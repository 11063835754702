// extracted by mini-css-extract-plugin
export var btnDefault = "number-card-module--btn-default--5jv4r";
export var attoiconJobs = "number-card-module--attoicon-jobs--wZn+k";
export var attoiconNotes = "number-card-module--attoicon-notes--YoY9i";
export var attoiconTimesheets = "number-card-module--attoicon-timesheets--BLqRv";
export var attoiconTimesheetsRounded1 = "number-card-module--attoicon-timesheets-rounded1--ClU7u";
export var attoiconMobileTt = "number-card-module--attoicon-mobile-tt--Yizcc";
export var attoiconNamedSites = "number-card-module--attoicon-named-sites--yCUGv";
export var attoiconProfitableBids = "number-card-module--attoicon-profitable-bids--n2Z6o";
export var attoiconDepartments = "number-card-module--attoicon-departments--1CIEC";
export var attoiconTimeline = "number-card-module--attoicon-timeline--aPZnu";
export var attoiconFilters = "number-card-module--attoicon-filters--FZPZy";
export var attoiconVerified = "number-card-module--attoicon-verified--rGxSN";
export var attoiconTimezone = "number-card-module--attoicon-timezone--2rw1M";
export var attoiconManualEntries = "number-card-module--attoicon-manual-entries--03nrJ";
export var attoiconJobCosting = "number-card-module--attoicon-job-costing--En6Wa";
export var attoiconLocationTracking = "number-card-module--attoicon-location-tracking--ty7a0";
export var attoiconBreak = "number-card-module--attoicon-break--sIFaK";
export var attoiconNightShift = "number-card-module--attoicon-night-shift--EhG5W";
export var attoiconOvertime = "number-card-module--attoicon-overtime--09jy0";
export var attoiconTimeTracking1 = "number-card-module--attoicon-time-tracking1--gjiDO";
export var attoiconTimeTracking = "number-card-module--attoicon-time-tracking--eff2s";
export var attoiconLocationHistory = "number-card-module--attoicon-location-history--tYyIy";
export var attoiconPrivacy = "number-card-module--attoicon-privacy--+9w5O";
export var attoiconTimeEntryHistory = "number-card-module--attoicon-time-entry-history--HCjGR";
export var attoiconCustomize = "number-card-module--attoicon-customize--R8g6M";
export var attoiconRoundedClock = "number-card-module--attoicon-rounded-clock--YAv-V";
export var attoiconTimeOff = "number-card-module--attoicon-time-off--tZQd+";
export var attoiconNamedLocations = "number-card-module--attoicon-named-locations--JpiE9";
export var attoiconSandClock = "number-card-module--attoicon-sand-clock--RL+PO";
export var attoiconBattery = "number-card-module--attoicon-battery--wsaiD";
export var attoiconSupport = "number-card-module--attoicon-support--GgK15";
export var attoiconHelpSupport = "number-card-module--attoicon-help-support--lQXh9";
export var attoiconWebTt = "number-card-module--attoicon-web-tt--LspgK";
export var attoiconArchive = "number-card-module--attoicon-archive--M85Ha";
export var attoiconEmail = "number-card-module--attoicon-email--5NccV";
export var attoiconKiosk = "number-card-module--attoicon-kiosk--5bVjR";
export var attoiconShare = "number-card-module--attoicon-share--rEE1Q";
export var attoiconCrew = "number-card-module--attoicon-crew--DcLM8";
export var attoiconTeamActivity = "number-card-module--attoicon-team-activity--kecgK";
export var attoiconTeam = "number-card-module--attoicon-team--kZ52v";
export var attoiconWages = "number-card-module--attoicon-wages--gnLkX";
export var attoiconNotification = "number-card-module--attoicon-notification--yvZAL";
export var attoiconAvatar = "number-card-module--attoicon-avatar--iZhvg";
export var attoiconViewMap = "number-card-module--attoicon-view-map--Q1g3E";
export var attoiconMovementTracking = "number-card-module--attoicon-movement-tracking--iDplK";
export var attoiconWageEstimates = "number-card-module--attoicon-wage-estimates--KexpV";
export var attoiconWageEstimatesBold = "number-card-module--attoicon-wage-estimates-bold--fyc01";
export var attoiconClose = "number-card-module--attoicon-close--bsOTu";
export var attoiconPlus = "number-card-module--attoicon-plus--kWKbS";
export var attoiconMinus = "number-card-module--attoicon-minus--0gGVS";
export var attoiconTick = "number-card-module--attoicon-tick--24Huv";
export var attoiconArrowLeft = "number-card-module--attoicon-arrow-left--2Lbfp";
export var attoiconArrowRight = "number-card-module--attoicon-arrow-right--EN9Q-";
export var attoiconArrowDown = "number-card-module--attoicon-arrow-down--rYdlT";
export var attoiconArrowUp = "number-card-module--attoicon-arrow-up--mZBVl";
export var attoiconPlay = "number-card-module--attoicon-play--pFSkn";
export var attoiconLongArrow = "number-card-module--attoicon-long-arrow--kYXj3";
export var container = "number-card-module--container--V6mBh";
export var padded = "number-card-module--padded--I+80z";
export var border = "number-card-module--border--OumbA";